/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  addUserAsync,
  deleteUsersAsync,
  fetchAllUsersAsync,
  resetUsersPasswordAsync,
  selectUsers,
} from './users.slice';
import UsersView from './users.view';

export interface IUsersContainer {}

const UsersContainer: React.FC<IUsersContainer> = ({}: IUsersContainer) => {
  const users = useAppSelector(selectUsers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchAllUsersAsync(null));
  }, []);

  const handleAddUser = (data: any) => {
    const { email, name, password } = data;
    dispatch(addUserAsync({ email, password, name }));
  };

  const handleDeleteUser = (id: string) => {
    dispatch(deleteUsersAsync(id));
  };

  const handleRestUserPassword = (data: any) => {
    navigator.clipboard.writeText(data.password);
    dispatch(resetUsersPasswordAsync(data));
  };

  return (
    <UsersView
      selectUser={users}
      usersList={users.list}
      handleAddUser={handleAddUser}
      handleDeleteUser={handleDeleteUser}
      handleRestUserPassword={handleRestUserPassword}
    />
  );
};

export default memo(UsersContainer);
