import React, { memo, useState } from 'react';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';

import { isPermissionAvailable } from '../../helper/permission';
import { Card } from '../../components/Card';
import { Typography } from '../../components/Typography';
import {
  ProfileSettings,
  TermsSettings,
  PrivacyPolicySettings,
  FAQSettings,
  ContactUsSettings,
  ScreenAppSettings,
  GeneralSettings,
} from './components';
import styles from './settings.styles';

import {
  ContactInfoIcon,
  FaqIcon,
  GeneralSettingsIcon,
  PrivacyPolicyIcon,
  ScreenappIcon,
  TermsIcon,
  UserProfileIcon,
} from '../../assets/icons';

interface ISettingsView extends WithStyles<typeof styles> {
  auth: any;
  settings: any;
  handleUpdateSettings: (data: any) => void;
  handleUpdateUserProfile: (data: any) => void;
  handleUpdateUserPassword: (data: any) => void;
  handleAddFAQ: (data: any) => void;
  handleUpdateFAQ: (data: any) => void;
  handleDeleteFAQ: (id: string) => void;
  handleUploadFile: (data: any) => any;
  handleUploadProfilePicture: (data: any) => any;
  handleUploadSystemLogo: (data: any) => any;
}
interface ITabPanel {
  children: React.ReactNode;
  index: number;
  value: number;
  permission: string;
}
interface ICustomTab {
  index: number;
  label: string;
  icon: string;
  permission: string;
}

const SettingsView: React.FC<ISettingsView> = ({
  classes,
  auth,
  settings,
  handleUpdateSettings,
  handleUpdateUserProfile,
  handleUpdateUserPassword,
  handleAddFAQ,
  handleUpdateFAQ,
  handleDeleteFAQ,
  handleUploadFile,
  handleUploadProfilePicture,
  handleUploadSystemLogo,
}: ISettingsView) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const _handleTabChange = (
    event: any,
    newValue: React.SetStateAction<number>
  ) => {
    setSelectedTab(newValue);
  };

  const tabList = [
    {
      label: 'User Profile',
      icon: UserProfileIcon,
      permission: 'editProfileSettings',
      component: () => (
        <ProfileSettings
          user={auth?.user}
          settings={settings}
          handleUpdateUserProfile={handleUpdateUserProfile}
          handleUpdateUserPassword={handleUpdateUserPassword}
          handleUploadFile={handleUploadProfilePicture}
        />
      ),
    },
    {
      label: 'General Settings',
      icon: GeneralSettingsIcon,
      permission: 'editGeneralSettings',
      component: () => (
        <GeneralSettings
          settings={settings}
          handleUploadFile={handleUploadSystemLogo}
          handleUpdateSettings={handleUpdateSettings}
        />
      ),
    },
    {
      label: 'Update Privacy Policy',
      icon: PrivacyPolicyIcon,
      permission: 'editPrivacyPolicySettings',
      component: () => (
        <PrivacyPolicySettings
          settings={settings}
          handleUpdateSettings={handleUpdateSettings}
        />
      ),
    },
    {
      label: 'Update Use Of Terms',
      icon: TermsIcon,
      permission: 'editTermsSettings',
      component: () => (
        <TermsSettings
          settings={settings}
          handleUpdateSettings={handleUpdateSettings}
        />
      ),
    },
    {
      label: 'FAQ',
      icon: FaqIcon,
      permission: 'editFAQSettings',
      component: () => (
        <FAQSettings
          settings={settings}
          handleAddFAQ={handleAddFAQ}
          handleUpdateFAQ={handleUpdateFAQ}
          handleDeleteFAQ={handleDeleteFAQ}
        />
      ),
    },
    {
      label: 'Contact Us Info',
      icon: ContactInfoIcon,
      permission: 'editContactInfoSettings',
      component: () => (
        <ContactUsSettings
          settings={settings}
          handleUpdateSettings={handleUpdateSettings}
        />
      ),
    },
    {
      label: 'Screenapp',
      icon: ScreenappIcon,
      permission: 'screenAppIntegration',
      component: () => (
        <ScreenAppSettings
          settings={settings}
          handleUpdateSettings={handleUpdateSettings}
        />
      ),
    },
  ];

  const a11yProps = (index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  };

  const TabPanel = ({
    children,
    index,
    value,
    permission,
    ...other
  }: ITabPanel) => {
    const canView = isPermissionAvailable(auth?.permissions, permission);
    return canView ? (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </div>
    ) : null;
  };

  const CustomTab = ({
    label,
    icon,
    index,
    permission,
    ...other
  }: ICustomTab) => {
    const canView = isPermissionAvailable(auth?.permissions, permission);

    return canView ? (
      <Tab
        className={classes.tab}
        label={
          <div className={classes.labelWrapper}>
            {label}
            <ArrowForwardIosIcon className={classes.arrow} />
          </div>
        }
        icon={<img className={classes.tabIcon} src={icon} alt={label} />}
        {...a11yProps(index)}
        {...other}
      />
    ) : null;
  };

  if (window?.innerWidth < 575) {
    return (
      <div className={classes.noMobileView}>
        <ReportProblemOutlinedIcon />
        <Typography variant="body1">
          Sorry, this page is not supported by mobiles.
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.topWrapper}>
        <Typography
          className={classes.heading}
          variant="h5"
          component="h3"
          color="textPrimary"
        >
          Settings
        </Typography>
      </div>
      <div className={classes.bottomWrapper}>
        <Card customStyles={classes.leftPanel}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={selectedTab}
            onChange={_handleTabChange}
          >
            {tabList?.map((tab, index) => (
              <CustomTab
                key={tab.label}
                label={tab.label}
                icon={tab.icon}
                index={index}
                permission={tab.permission}
              />
            ))}
          </Tabs>
        </Card>
        <div className={classes.rightPanel}>
          {tabList?.map((tab, index) => (
            <TabPanel
              value={selectedTab}
              index={index}
              permission={tab.permission}
            >
              {tab?.component()}
            </TabPanel>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(withStyles(styles)(SettingsView));
