import React, { useState } from 'react';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { DateRange, VideocamOutlined } from '@material-ui/icons';
import { IconButton } from '../../../components/Button';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
  });
};

interface IScheduleMeetingButtonsProps extends WithStyles<typeof styles> {
  scheduleMeetingBtnClick: () => void;
  instanteMeetingBtnClick: () => void;
}

const ScheduleMeetingButtons: React.FC<IScheduleMeetingButtonsProps> = ({
  classes,
  scheduleMeetingBtnClick,
  instanteMeetingBtnClick,
}: IScheduleMeetingButtonsProps) => {
  return (
    <>
      <IconButton
        id="schedule-meeting-button"
        label="Schedule a meeting"
        icon={<DateRange />}
        variant="contained"
        onClick={scheduleMeetingBtnClick}
      />
      <IconButton
        id="create-instant-meeting-button"
        label="Create an Instant meeting"
        icon={<VideocamOutlined />}
        variant="outlined"
        onClick={instanteMeetingBtnClick}
      />
    </>
  );
};

export default withStyles(styles)(ScheduleMeetingButtons);
