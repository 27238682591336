/* eslint-disable react/require-default-props */
import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Button } from '../../../components/Button';
import { Card } from '../../../components/Card';
import { Typography } from '../../../components/Typography';
import { TextField, SelectTextField } from '../../../components/TextField';
import { ImageUploadInput } from '../../../components/ImageUploadInput';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    wrapper: {
      marginBottom: theme.spacing(3),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
    fileUploadWrapper: {
      maxWidth: 400,
    },
    leftWrapper: {
      backgroundColor: theme.palette.grey[100],
      height: '100%',
      padding: '0 10px',
      display: 'flex',
      '& .MuiInputBase-input': {
        color: theme.palette.grey[700],
      },
    },
    rightWrapper: {
      height: '100%',
      '& img': {
        maxHeight: '100%',
      },
    },
  });
};
interface IGeneralSettingsView extends WithStyles<typeof styles> {
  settings: any;
  handleUpdateSettings: (data: any) => void;
  handleUploadFile: (data: any) => any;
}

const GeneralSettingsView: React.FC<IGeneralSettingsView> = ({
  classes,
  settings,
  handleUpdateSettings,
  handleUploadFile,
}: IGeneralSettingsView) => {
  const [title, setTitle] = useState<any>({
    value: '',
    error: false,
  });
  const [companyName, setCompanyName] = useState<any>({
    value: '',
    error: false,
  });
  const [timeZone, setTimeZone] = useState<any>({
    value: 1,
    error: false,
  });
  const [dateFormat, setDateFormat] = useState<any>({
    value: 1,
    error: false,
  });
  const [meetingEndText, setMeetingEndText] = useState<any>({
    value: '',
    error: false,
  });
  const [logo, setLogo] = useState<any>({
    value: null,
    error: false,
  });
  const [meetingEndBanner, setMeetingEndBanner] = useState<any>({
    value: null,
    error: false,
  });

  const _titleOnChange = (e: any) => {
    setTitle({ value: e.target.value, error: false });
  };

  const _companyNameOnChange = (e: any) => {
    setCompanyName({ value: e.target.value, error: false });
  };

  const _dateFormatOnChange = (e: any) => {
    setDateFormat({ value: e.target.value, error: false });
  };

  const _timeZoneOnChange = (e: any) => {
    setTimeZone({ value: e.target.value, error: false });
  };

  const _meetingEndTextOnChange = (e: any) => {
    setMeetingEndText({ value: e.target.value, error: false });
  };

  const _fileOnChange = async (e: any, type: string) => {
    const file = new FormData();
    file.append('inputFile', e.target?.files[0]);
    const data = {
      file,
      type,
    };

    const response = await handleUploadFile(data);

    if (type === 'logo') {
      if (response?.success) setLogo({ value: response?.data[0], error: '' });
      else setLogo({ value: null, error: 'file not uploaded' });
    } else if (type === 'banner') {
      if (response?.success)
        setMeetingEndBanner({ value: response?.data[0], error: '' });
      else setMeetingEndBanner({ value: null, error: 'file not uploaded' });
    }
  };

  useEffect(() => {
    if (settings?.data?.general) {
      const info = settings.data.general;
      setTitle({ value: info.title, error: false });
      setTimeZone({ value: info.timeZone, error: false });
      setCompanyName({ value: info.companyName, error: false });
      setDateFormat({ value: info.dateFormat, error: false });
      setMeetingEndText({ value: info.meetingEndText, error: false });
      setLogo({ value: { location: info.logo }, error: false });
      setMeetingEndBanner({
        value: { location: info.meetingEndBanner },
        error: false,
      });
    }
  }, [settings?.data?.general]);

  const _validate = () => {
    let isValidate = true;
    if (title.error || companyName.error || meetingEndText.error || logo.error)
      isValidate = false;

    if (!title.value) {
      isValidate = false;
      setTitle({ ...title, error: 'Title is Required' });
    }
    if (!companyName.value) {
      isValidate = false;
      setCompanyName({ ...companyName, error: 'Company Name is Required' });
    }
    if (!meetingEndText.value) {
      isValidate = false;
      setMeetingEndText({
        ...meetingEndText,
        error: 'Meeting End Text is Required',
      });
    }
    if (!logo.value) {
      isValidate = false;
      setLogo({ ...logo, error: 'Logo is Required' });
    }
    return isValidate;
  };
  const _handleSaveButtonClick = () => {
    if (_validate()) {
      const data = {
        setting: {
          title: title.value,
          timeZone: timeZone.value,
          companyName: companyName.value,
          dateFormat: dateFormat.value,
          meetingEndText: meetingEndText.value,
          logo: logo.value?.location || logo.value?.path,
          meetingEndBanner:
            meetingEndBanner.value?.location || meetingEndBanner.value?.path,
        },
        category: 'general',
      };
      handleUpdateSettings(data);
    }
  };

  const TimeZonesList = [
    {
      label: '+5:00 Sample',
      value: 1,
    },
    {
      label: '+5:30 Sample',
      value: 2,
    },
    {
      label: '+6:00 Sample',
      value: 3,
    },
  ];

  const DateFormats = [
    {
      label: 'DD/MM/YY',
      value: 1,
    },
    {
      label: 'MM/DD/YY',
      value: 2,
    },
    {
      label: 'YY/MM/DD',
      value: 3,
    },
  ];

  return (
    <div>
      <Card customStyles={classes.root}>
        <div className={classes.wrapper}>
          <Typography component="h3" variant="h6">
            Contact Info
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <Grid container spacing={2}>
            <Grid item sm={6} lg={3}>
              <TextField
                label="System title"
                value={title.value}
                onChange={_titleOnChange}
                required
                error={Boolean(title.error)}
                helperText={title.error}
              />
            </Grid>
            {/* <Grid item sm={6} lg={3}>
              <SelectTextField
                label="TimeZone"
                value={timeZone.value}
                onChange={_timeZoneOnChange}
                selectItems={TimeZonesList}
              />
            </Grid> */}
            <Grid item sm={6} lg={3}>
              <TextField
                label="Company Name"
                value={companyName.value}
                onChange={_companyNameOnChange}
                required
                error={Boolean(companyName.error)}
                helperText={companyName.error}
              />
            </Grid>
            {/* <Grid item sm={6} lg={3}>
              <SelectTextField
                label="Date Format"
                value={dateFormat.value}
                onChange={_dateFormatOnChange}
                selectItems={DateFormats}
              />
            </Grid> */}
            <Grid item sm={6} lg={3}>
              <TextField
                label="Meeting end page text*"
                value={meetingEndText.value}
                onChange={_meetingEndTextOnChange}
                required
                error={Boolean(meetingEndText.error)}
                helperText={meetingEndText.error}
              />
            </Grid>
          </Grid>
        </div>
        <div className={classes.fileUploadWrapper}>
          <ImageUploadInput
            id="general-settings-system-logo-upload"
            inputTitle="System Logo*"
            name="logoFile"
            value={logo.value?.location || meetingEndBanner.value?.path}
            onChange={(e) => _fileOnChange(e, 'logo')}
          />
          <ImageUploadInput
            id="general-settings-banner-upload"
            inputTitle="Meeting end page banner (760 x 480 px)"
            name="endBannerFile"
            value={
              meetingEndBanner.value?.location || meetingEndBanner.value?.path
            }
            onChange={(e) => _fileOnChange(e, 'banner')}
          />
        </div>
        <Button
          id="update-profile-save-button"
          variant="contained"
          label="Save"
          onClick={_handleSaveButtonClick}
        />
      </Card>
    </div>
  );
};

export default memo(withStyles(styles)(GeneralSettingsView));
