import React, { memo, useState, useEffect } from 'react';
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import { Button } from '../../../components/Button';
import { Typography } from '../../../components/Typography';
import { TextField, PasswordTextField } from '../../../components/TextField';
import { Card } from '../../../components/Card';
// import styles from '../settings.styles';
import { validatePassword } from '../../../helper/validation';
import { ImageUploadInput } from '../../../components/ImageUploadInput';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
    },
    rightCard: {
      marginLeft: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(2),
    },
    description: {
      margin: theme.spacing(1, 0),
    },
  });
};

interface IProfileSettingsView extends WithStyles<typeof styles> {
  user: any;
  settings: any;
  handleUpdateUserProfile: (data: any) => void;
  handleUpdateUserPassword: (data: any) => void;
  handleUploadFile: (data: any) => any;
}

const ProfileSettingsView: React.FC<IProfileSettingsView> = ({
  classes,
  user,
  settings,
  handleUpdateUserProfile,
  handleUpdateUserPassword,
  handleUploadFile,
}: IProfileSettingsView) => {
  const [name, setName] = useState<any>({ value: '', error: false });
  const [currentPassword, setCurrentPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [newPassword, setNewPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [confirmPassword, setConfirmPassword] = useState<any>({
    value: '',
    error: false,
  });
  const [profileImage, setProfileImage] = useState<any>({
    value: null,
    error: false,
  });

  useEffect(() => {
    if (user) {
      setName({ value: user?.profile?.name, error: false });
      setProfileImage({ value: user?.profile?.picture, error: false });
    }
  }, []);

  useEffect(() => {
    if (user) {
      setName({ value: user?.profile?.name, error: false });
      setProfileImage({ value: user?.profile?.picture, error: false });
    }
  }, [user]);

  const _nameOnChange = (e: any) => {
    setName({ value: e.target.value, error: false });
  };

  const _updateProfileButtonOnClick = () => {
    const data = {
      name: name.value,
      picture:
        profileImage.value?.location ||
        profileImage.value?.path ||
        profileImage.value,
    };
    handleUpdateUserProfile(data);
  };

  const _currentPasswordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setCurrentPassword({ value: e.target.value, error });
  };

  const _newPasswordOnChange = (e: { target: { value: string } }) => {
    const error = validatePassword(e.target.value);
    setNewPassword({ value: e.target.value, error });
  };

  const _confirmPasswordOnChange = (e: { target: { value: string } }) => {
    const error = e.target.value !== newPassword.value;
    setConfirmPassword({ value: e.target.value, error });
  };

  const _validateChangePassword = () => {
    let isValidate = true;

    if (
      currentPassword.error ||
      newPassword.error ||
      confirmPassword.error ||
      profileImage.error
    )
      isValidate = false;

    if (!currentPassword.value) {
      isValidate = false;
      setCurrentPassword({ ...currentPassword, error: true });
    }

    if (!newPassword.value) {
      isValidate = false;
      setNewPassword({ ...newPassword, error: true });
    }

    if (confirmPassword.value !== newPassword.value) {
      isValidate = false;
      setConfirmPassword({ ...confirmPassword, error: true });
    }

    return isValidate;
  };

  const _updatePasswordeButtonOnClick = () => {
    if (_validateChangePassword()) {
      const data = {
        oldPassword: currentPassword.value,
        password: newPassword.value,
      };
      handleUpdateUserPassword(data);
    }
  };

  const _fileOnChange = async (e: any, type: string) => {
    const file = new FormData();
    file.append('inputFile', e.target?.files[0]);
    const data = {
      file,
      type,
    };

    const response = await handleUploadFile(data);
    if (response?.success)
      setProfileImage({ value: response?.data[0], error: '' });
    else setProfileImage({ value: null, error: 'file not uploaded' });
  };
  return (
    <Grid container className={classes.root}>
      <Grid item xs={6}>
        <Card>
          <Typography component="h3" variant="h6">
            Update User Profile
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <TextField
            label="Name"
            placeholder="Name"
            value={name.value}
            onChange={_nameOnChange}
          />
          <TextField label="Name" value={user?.email} disabled />
          <TextField label="Role" value={user?.role} disabled />
          <ImageUploadInput
            id="profile-image-upload-input"
            inputTitle="Profile Picture"
            name="profileImage"
            value={
              profileImage.value?.location ||
              profileImage.value?.path ||
              profileImage.value
            }
            onChange={(e) => _fileOnChange(e, 'profileImage')}
          />
          <Button
            customStyles={classes.button}
            id="update-profile-save-button"
            variant="contained"
            label="Save"
            onClick={_updateProfileButtonOnClick}
          />
        </Card>
      </Grid>
      <Grid item xs={6}>
        <Card customStyles={classes.rightCard}>
          <Typography component="h3" variant="h6">
            Change Password
          </Typography>
          <Typography
            component="h4"
            variant="body2"
            className={classes.description}
          >
            The Field Labels Marked With * Are Required Input Fields.
          </Typography>
          <PasswordTextField
            id="current-password"
            label="Current Password"
            onChange={_currentPasswordOnChange}
            error={currentPassword.error}
            helperText={
              currentPassword.error && 'Please insert a valid password'
            }
          />
          <PasswordTextField
            id="new-password"
            label="New Password"
            onChange={_newPasswordOnChange}
            error={newPassword.error}
            helperText={newPassword.error && 'Please insert a valid password'}
          />
          <PasswordTextField
            id="confirm-password"
            label="Confirm Password"
            onChange={_confirmPasswordOnChange}
            error={confirmPassword.error}
            helperText={confirmPassword.error && 'Password mismatch'}
          />
          <Button
            customStyles={classes.button}
            id="update-profile-save-button"
            variant="contained"
            label="Update Password"
            onClick={_updatePasswordeButtonOnClick}
          />
        </Card>
      </Grid>
    </Grid>
  );
};

export default memo(withStyles(styles)(ProfileSettingsView));
