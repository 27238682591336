import { combineReducers } from 'redux';

import { authReducer } from '../features/auth';
import { meetingsReducer } from '../features/meetings';
import { scheduleMeetingsReducer } from '../features/scheduleMeeting';
import { usersReducer } from '../features/users';
import { appReducer } from '../features/app';
import { meetingRoomReducer } from '../features/meetingRoom';
import { settingsReducer } from '../features/settings';
import { meetingReportsReducer } from '../features/reports/meetingReport';

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  settings: settingsReducer,
  meetings: meetingsReducer,
  scheduleMeeting: scheduleMeetingsReducer,
  meetingRoom: meetingRoomReducer,
  users: usersReducer,
  reports: meetingReportsReducer,
});

export default rootReducer;
