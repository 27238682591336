import React, { memo } from 'react';
import moment from 'moment';
import { Theme } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import ShareIcon from '@material-ui/icons/Share';
import { Modal } from '../../../components/Modal';
import { CopyTextField } from '../../../components/TextField';
import { IconButton } from '../../../components/Button';
import { generateMeetingUrl } from '../../../helper/conference';

const styles = (theme: Theme) => {
  return createStyles({
    root: {},
    copyTextfield: {
      color: theme.palette.secondary.main,
    },
  });
};

export interface IShareMeetingModalProps extends WithStyles<typeof styles> {
  customStyles?: any;
  open: boolean;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  meeting: any;
}

const ShareMeetingModal = ({
  classes,
  open,
  handleClose,
  meeting,
}: IShareMeetingModalProps) => {
  const generateEmail = () => {
    const subject = `You have a meeting scheduled on MM for   ${moment(
      meeting?.beginTime
    ).format('YYYY/MM/DD - hh:mm A')} `;
    const message = `Hi,
    
Meeting Topic : ${meeting?.name}
Meeting Created by : ${meeting?.host?.profile?.name} | ${meeting?.host?.email}
Date : ${moment(meeting?.endTime).format('YYYY/MM/DD')}
Time : ${moment(meeting?.beginTime).format('hh:mm A')} - ${moment(
      meeting?.endTime
    ).format('hh:mm A')}
Meeting Link : ${generateMeetingUrl(meeting?.location)}

Please use Google Chrome or other popular supported web browsers to join this meeting. 

Powered by Jitsi Meeting Manager - meetrix.io`;

    return `mailto:?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(message)}`;
  };

  return (
    <Modal
      modalTitle="Schedule a meeting"
      selectedItem={meeting?.location}
      modalDescription="Your meeting has been successfully created. Share this meeting link with others to start your meeting. Copy link or share your Invitation with others"
      open={open}
      handleClose={handleClose}
      actionButtons={
        <IconButton
          href={generateEmail()}
          id="share-button"
          variant="contained"
          label="Share invitation"
          icon={<ShareIcon />}
        />
      }
    >
      <CopyTextField
        value={generateMeetingUrl(meeting?.location)}
        placeholder="Meeting Name"
        customStyles={classes.copyTextfield}
      />
    </Modal>
  );
};

export default memo(withStyles(styles)(ShareMeetingModal));
