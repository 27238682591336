/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { memo, useEffect, useState } from 'react';
import ScheduleMeetingButtonsView from './scheduleMeetingButtons.view';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { selectSchesuleMeeting, actions } from '../scheduleMeeting.slice';

export interface IScheduleMeetingModalContainer {}

const ScheduleMeetingModalContainer: React.FC<IScheduleMeetingModalContainer> = ({}: IScheduleMeetingModalContainer) => {
  const schesuleMeeting = useAppSelector(selectSchesuleMeeting);
  const dispatch = useAppDispatch();
  // const history = useHistory();
  const _scheduleMeetingBtnClick = () => {
    dispatch(actions.openScheduleModal());
  };
  const _instanteMeetingBtnClick = () => {
    dispatch(actions.openInstantMeetingModal());
  };

  return (
    <ScheduleMeetingButtonsView
      scheduleMeetingBtnClick={_scheduleMeetingBtnClick}
      instanteMeetingBtnClick={_instanteMeetingBtnClick}
    />
  );
};

export default memo(ScheduleMeetingModalContainer);
